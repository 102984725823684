<template lang="pug">
	.mx-auto.form-profile
		h1.mb-4(v-if="me && !me.hasFilledAccount()") {{ $t('project.profile') }}

		.card-basic
			p.b1.mb-3 {{ $t('project.paymentAccounts.h2') }}

			b-form.d-flex.flex-column(autocomplete='off')
				my-input.mb-4(
					v-model.trim="form.surname"
					:label="$t('forms.placeholder.surname')"
					:errorFeedback="errors.surname"
					:icon="'icon-profile'"
					@blur='isSurNameValid(form.surname), validation(form)'
				)
				my-input.mb-4(
					v-model.trim="form.name"
					:label="$t('forms.placeholder.givenName')"
					:errorFeedback="errors.name"
					:icon="'icon-profile'"
					@blur='isNameValid(form.name), validation(form)'
				)
				my-input.mb-4(
					v-model.trim="form.middleName"
					:label="$t('forms.placeholder.middleName')"
					:errorFeedback="errors.middleName"
					:icon="'icon-profile'"
					@blur='isMiddleNameValid(form.middleName), validation(form)'
				)
				my-input(
					v-model.trim="form.email"
					:disabled='me.emailPersonal ? true : false'
					:label="$t('forms.email')"
					:errorFeedback="form.email == '' ? $t('promotions.form.error') : ''"
					:icon="'icon-sms'"
					:type="'email'"
					:classInput="{'is-error': errorEmail}"
					:class="errorEmail && form.email && form.email.length > 0 ? 'mb-1' : 'mb-4'"
				)
				.text-danger.b4.mb-4.payment-confirm__error(v-if="errorEmail && form.email &&  form.email.length > 0") {{ $t('errors.valid.email') }}
				my-input(
					v-model="form.phone"
					:label="$t('forms.placeholder.phone')"
					:errorFeedback="form.phone == '' ? $t('errors.required.enterPhone') : ''"
					:icon="'icon-call'"
					:type="'tel'"
					v-mask="'+7 (###) ###-##-##'"
					:classInput="{'is-error': errorPhone}"
					:class="errorPhone && form.phone && form.phone.length > 0 ? 'mb-1' : 'mb-4'"
				)
				.text-danger.b4.mb-4.payment-confirm__error(v-if="errorPhone && form.phone && form.phone.length > 0") {{ $t('errors.numeric.phone') }}

				b-form-checkbox.mb-4.form-profile__confirmation(id="confirmation" v-model="form.isConfirmation" name="confirmation" :value="true" :unchecked-value="false") {{ $t('h1.confirmation') }}

				dropdown-general.mb-4(:list="dropdownList" :size="'large'" @onChange="selectCountry" :disabled='true')

				span.btn.btn-primary.w-100.mt-auto(@click="saveProfile" :class="{'disabled': isDisabled || isPending}") {{ $t('forms.save') }}
</template>

<script>
import MyInput from '@/components/UI/MyInput';
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';
import { COUNTRIES, isoMethods } from '@/constants/countries';
import { SERVICE_STRING } from '@/utils/string';
import { mapActions, mapGetters } from 'vuex';

const kiril = value => {
    if (value == null) return false;
    if (_.some(value.split('-'), v => v == '')) return false;
    if (_.some(value.split('—'), v => v == '')) return false;

    return /^([а-яА-ЯёЁ\-\—\s\.])+$/.test(value);
};

export default {
    name: 'FormProfile',
    components: {
        MyInput,
        DropdownGeneral,
    },
    data: () => ({
        isDisabled: true,
        form: {
            name: null,
            surname: null,
            middleName: null,
            email: null,
            phone: '+7 (',
            isConfirmation: false,
            country: null,
        },
        errors: {
            name: '',
            surname: '',
            middleName: '',
        },
        errorEmail: false,
        errorPhone: false,
        dropdownList: _.map(COUNTRIES, v => {
            return { id: v.alpha3, title: isoMethods.getName(v.alpha3) };
        }),
    }),
    computed: {
        ...mapGetters({
            isPending: 'auth/isPending',
        }),
    },
    created() {
        this.setDefault();
        this.validation(this.form);
    },
    methods: {
        ...mapActions({
            editMe: 'auth/meEdit',
        }),
        setDefault() {
            this.form.country = this.dropdownList[0];

            if (!this.me) return;
            if (this.me.fio) {
                let fio = this.me.fio.split(' ');
                this.form.surname = fio[0] ? fio[0] : null;
                this.form.name = fio[1] ? fio[1] : null;
                this.form.middleName = fio[2] ? fio[2] : null;
            }

            if (this.me.country)
                this.form.country = _.find(
                    this.dropdownList,
                    c => c.id == this.me.country || c.title == this.me.country
                );

            this.form.email = this.me.emailPersonal;
            this.form.phone = this.me.phone || '+7 (';
        },
        validation(val) {
            this.isDisabled = !(
                val.name != null &&
                val.name != '' &&
                this.errors.name == '' &&
                val.surname != null &&
                val.surname != '' &&
                this.errors.surname == '' &&
                val.middleName != null &&
                val.middleName != '' &&
                this.errors.middleName == '' &&
                val.phone != null &&
                val.phone != '' &&
                val.phone != '+7 (' &&
                this.errorPhone == '' &&
                val.isConfirmation != false
            );
        },
        async saveProfile() {
            this.editMe({
                id: this.me.id,
                fio: [this.form.surname, this.form.name, this.form.middleName].join(' '),
                phone: this.form.phone,
                country: this.form.country.id,
                emailPersonal: this.form.email,
            });
        },
        isNameValid(v) {
            this.errors.name = '';

            if (v == null || v.length == 0) {
                this.errors.name = this.$t('errors.required.givenName');
            } else if (v.length < 2) {
                this.errors.name = this.$t('errors.minLength.name');
            } else if (v.length > 50) {
                this.errors.name = this.$t('errors.maxLength.firstName');
            }

            if (v != null && v != '' && !kiril(v)) this.errors.name = this.$t('errors.valid.kirilOnly');
        },
        isSurNameValid(v) {
            this.errors.surname = '';

            if (v == null || v.length == 0) {
                this.errors.surname = this.$t('errors.required.surname');
            } else if (v.length < 2) {
                this.errors.surname = this.$t('errors.minLength.surname');
            } else if (v.length > 50) {
                this.errors.surname = this.$t('errors.maxLength.lastName');
            }

            if (v != null && v != '' && !kiril(v)) this.errors.surname = this.$t('errors.valid.kirilOnly');
        },
        isMiddleNameValid(v) {
            this.errors.middleName = '';

            if (v == null || v.length == 0) {
                this.errors.middleName = this.$t('errors.required.middleName');
            } else if (v.length < 2) {
                this.errors.middleName = this.$t('errors.minLength.middleName');
            } else if (v.length > 50) {
                this.errors.middleName = this.$t('errors.maxLength.middleName');
            }

            if (v != null && v != '' && !kiril(v)) this.errors.middleName = this.$t('errors.valid.kirilOnly');
        },

        selectCountry(v) {
            this.form.country = _.find(this.dropdownList, c => c.id == v || c.title == v);
        },
    },
    watch: {
        'form.email': {
            handler(v) {
                if (!SERVICE_STRING.isValidEmail(v)) {
                    this.errorEmail = true;
                    this.isDisabled = true;
                } else {
                    this.errorEmail = false;
                    this.isDisabled = false;
                }
            },
            deep: true,
        },
        'form.phone': {
            handler(v) {
                if (!SERVICE_STRING.isValidPhone(v)) {
                    this.errorPhone = true;
                    this.isDisabled = true;
                } else if (v.split('')[4] != 9) {
                    this.errorPhone = true;
                    this.isDisabled = true;
                } else {
                    this.errorPhone = false;
                    this.isDisabled = false;
                }
            },
            deep: true,
        },
        form: {
            handler(val, oldVal) {
                this.validation(val);
            },
            deep: true,
        },
        me(v) {
            this.setDefault();
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.form-profile {
    width: 100%;
    max-width: 550px;

    @include respond-below(sm) {
        max-width: 100%;
    }

    &__confirmation {
        label {
            padding-top: 0;
            padding-left: 10px;
            color: var(--grey-text-color) !important;
        }
    }
}
</style>
